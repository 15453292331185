.App {
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.copyCard {
  flex-basis: auto;
  flex-shrink: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7pt;
}

.headertitle {
  font-size: 10pt;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: gray;
}

.successcopy {
  display: flex;

  flex-direction: column;
  align-items: center;
}

.successtext {
  font-size: 18pt;

  color: green;
}

.womanprops {
  font-size: 15pt;
}

.biography {
  font-weight: 600;
}

.letterDisplay {
  border: 1pt solid black;
}

.accordion-button {
  font-size: 18pt;
  text-align: center;
  font-weight: 400;
}

.accordiontext {
  text-align: left;
  list-style-position: outside;
}

.cardtitle {
  font-size: 18pt;
  text-align: center;
  font-weight: 400;
}

.answerwithkeyboard {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

/* padding for larger screen */
/* https://www.smashingmagazine.com/2010/07/how-to-use-css3-media-queries-to-create-a-mobile-version-of-your-website/ */
@media only screen and (min-device-width: 400px) {
  .answerwithkeyboard {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding-left: 20pt;
    align-items: center;
  }
}

.answervalue {
  font-size: 25pt;
}

.keyboard {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.keyboardline {
  display: flex;
  flex-wrap: nowrap;
}

.cardkeyboard {
  padding-top: 20pt;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.letterbutton {
  font-size: 10pt;
  border: black;

  border-radius: 20%;
}

.backspacebutton {
  border-radius: 10%;
  border: black;
}

.guessbutton {
  border-radius: 10%;
}

/* https://www.w3schools.com/howto/howto_css_image_center.asp */
.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 1%;
  width: 25%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
